/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap';
@import "app/styles/global";
@import "app/styles/constants";
@import "app/styles/fonts";

.link-text {
  font-weight: 600;
  font-size: 16px;
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }
}

.green {
  color: $iswint-green;
}

.yellow {
  color: $iswint-yellow;
}

.purple {
  color: $iswint-purple;
}

.bg-green {
  background-color: $iswint-green;
}

.bg-yellow {
  background-color: $iswint-yellow;
}

.bg-purple {
  background-color: $iswint-purple;
}

.bg-light-green {
  background-color: #ECF8EC;
}

.bold {
  font-weight: 700;
}

.box {
  border-radius: 20px;
  font-weight: 600;
  font-size: 13px;
  @media only screen and (max-width: 1200px) {
    font-size: 12px;
  }
}

.square-box {
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 380px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.title {
  font-weight: bold;
  font-size: 37px;
  line-height: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 30px;
  }
}

.padding-header {
  padding-top: 100px;
  padding-bottom: 100px;
}

.iswint-gradient {
  background: linear-gradient(98.13deg, #29712A 0%, #44B300 100%);
  box-shadow: 0 8px 16px rgba(41, 113, 42, 0.56);
  border-radius: 8px;
}

.animated {
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(8em);
  transition: all .5s ease-in-out;
}

[data-aos] {
  body[data-aos-easing="bounce"] &,
  &[data-aos][data-aos-easing="bounce"] {
    transition-timing-function: cubic-bezier(.43, 1.95, .5, 1);
  }
}

