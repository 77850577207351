$iswint-green: #29712A;
$iswint-yellow: #fedf00;
$iswint-purple: #7d3795;
$light-yellow: #fffc96;

$header-menu-height: 63px;
$top-margin: 75px;

$dark-green: #298230;
$light-green: #45db51;
$red: #ff0000;

// TODO: Remove Unihack colors, replace with iswint colors.
$primary-color: $iswint-green;
$secondary-color: $iswint-yellow;
$tertiary-color: #00092D;
$quaternary-color: #47009C;

$white: white;
$black: black;
$green: #29712A;

$error-color: crimson;

$gray-0: #EEEEEE;
$gray-1: #C8C8C8;
$gray-2: #788386;
$gray-3: #4F4F4F;


$font-x-large: 2em;
$font-large: 1.3em;
$font-regular: 1em;
$font-small: 0.75em;

$shadow-primary: #29712A6b;
$shadow-secondary: #fedf006b;
$shadow-tertiary: rgba(0, 9, 45, 0.42);

@mixin shadow-primary() {
  -webkit-box-shadow: 0 6px 12px $shadow-primary !important;
  -moz-box-shadow: 0 6px 12px $shadow-primary !important;
  box-shadow: 0 6px 12px $shadow-primary !important;
}

@mixin shadow-secondary() {
  -webkit-box-shadow: 0 6px 12px $shadow-secondary !important;
  -moz-box-shadow: 0 6px 12px $shadow-secondary !important;
  box-shadow: 0 6px 12px $shadow-secondary !important;
}

@mixin shadow-tertiary() {
  -webkit-box-shadow: 0 6px 12px $shadow-tertiary !important;
  -moz-box-shadow: 0 6px 12px $shadow-tertiary !important;
  box-shadow: 0 6px 12px $shadow-tertiary !important;
}

@mixin shadow-pressed($color) {
  -webkit-box-shadow: 0 3px 6px $color !important;
  -moz-box-shadow: 0 3px 6px $color !important;
  box-shadow: 0 3px 6px $color !important;
}

@mixin transition() {
  transition: all .2s cubic-bezier(.26, 1.35, .82, 1.14);
}

@mixin transition-2() {
  transition: all .4s cubic-bezier(.26, 1.35, .82, 1.14);
}

@mixin gradient-primary() {
  background: linear-gradient(240deg, #1E003B 0%, $quaternary-color 100%);
}

@mixin gradient-secondary() {
  background: linear-gradient(240deg, #FFDF40 0%, #FF8359 100%);
}

@mixin gradient-tertiary() {
  background: linear-gradient(240deg, #6E67F2 0%, #8739E5 100%);
}
