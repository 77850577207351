@import "constants";

* {
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, span, small {
  color: $gray-3;
}

h1, h2 {
  font-weight: bold;
}

p {
  font-size: 18px;
  color: $gray-3;
  font-weight: 300;
  line-height: 28px;
}

@media (max-width: 577.98px) {
  p {
    font-size: 16px !important;
    font-weight: 300;
    color: $gray-3;
    line-height: 24px !important;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.6em;
  }
}

span {
  color: $iswint-green;
}

a {
  color: $iswint-green;

  &:hover {
    text-decoration: none;
    color: $iswint-green;
    cursor: pointer;
  }
}

.titles {
  color: $secondary-color;
  font-size: $font-x-large;
  margin-bottom: 35px;
  font-weight: bold;
  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }
}

.gray {
  color: $gray-3;
}

.subtitle {
  margin-bottom: 70px;
  color: $secondary-color;
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.full-height-vh {
  min-height: 100vh;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link-text {
  color: $secondary-color;
}

.link-padding {
  margin-top: 2em;
  margin-bottom: 1em;
}

.vector-right {
  position: relative;
  top: -2px;
  padding-left: 4px;

}

.link-text:hover {
  color: $secondary-color;
  text-decoration: none;
}

.banner-background-container {
  position: relative;
  transition: padding-top .3s ease-out;

  .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;

    background-size: auto 65%;
    background-repeat: no-repeat;
    //background-position: bottom;
    background-position: bottom right;
    background-image: url("/assets/images/particles.svg");
    @media screen and (max-width: 991px) {
      background-size: auto 55%;
    }

    @media screen and (max-width: 768px) {
      background-size: auto 50%;
    }

    @media screen and (max-width: 540px) {
      background-size: auto 45%;
    }
  }
}

.inner-shadow-top {
  box-shadow: inset 0 20px 40px -20px rgba(0, 0, 0, 0.4);
}

.btn {
  height: 48px;
  padding-left: 1em;
  padding-right: 1em;

  border-radius: 24px;
}

input {
  min-height: 48px;
  font-family: Roboto, sans-serif;
}

.orange-button {
  color: $tertiary-color;
  background-color: $secondary-color;
  border-width: 0;
  margin-bottom: 12px;

  @include shadow-secondary();
  @include gradient-secondary();

  &:active {
    @include shadow-pressed($shadow-primary);
    color: $white;
    background-color: $primary-color;
  }
}

.purple-button {
  color: white;
  background-color: $primary-color;
  border-width: 0;
  margin-bottom: 12px;

  @include shadow-primary();
  @include gradient-primary();

  &:active {
    @include shadow-pressed($shadow-primary);
    color: $white;
    background-color: $primary-color;
  }
}

.hover-shadow:hover {
  box-shadow: 1rem 1rem 1rem 1rem rgba(0, 0, 0, .15);
  transition: box-shadow 0.3s ease-in-out;
}

.carousel-indicators button {
  background-color: $iswint-green !important;
  height: 10px !important;
  width: 40px !important;
}


// /*Funny Carousel effect*/
//.carousel-item {
//  /* Force to always be block */
//  display: block !important;
//  z-index: 1;
//  position: absolute;
//  transform: translateX(100%);
//  opacity: 0;
//  transition:all  1s ease-out;
//  visibility: hidden;
//}
//
//.carousel-item.active {
//  position: relative;
//  transform: translateX(0);
//  visibility: visible ;
//  opacity: 1;
//}
