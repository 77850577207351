@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat/Montserrat-Regular.woff) format("woff"),
  url(../../assets/fonts/montserrat/Montserrat-Regular.woff2) format("woff2");
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat/Montserrat-ExtraBold.woff2) format('woff2'),
  url(../../assets/fonts/montserrat/Montserrat-ExtraBold.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat/Montserrat-SemiBold.woff2) format('woff2'),
  url(../../assets/fonts/montserrat/Montserrat-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat/Montserrat-Black.woff2) format('woff2'),
  url(../../assets/fonts/montserrat/Montserrat-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat/Montserrat-Bold.woff2) format('woff2'),
  url(../../assets/fonts/montserrat/Montserrat-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}
